import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

export default function History() {
  const { file, dataJson: { clients } } = useStaticQuery(graphql`
    query History {
      file(relativePath: { eq: "index/nz-contours-topo-1250k.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 800, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dataJson {
          clients {
          id
          name
          icon {
            publicURL
          }
        }
      }
    }
  `)

  const backgroundFluidImageStack = [
    file.childImageSharp.fluid,
    `linear-gradient(108.11deg, #F2F7FF 13.44%, rgba(242, 247, 255, 0) 74.87%)`
  ].reverse()

  return (
    <section className='history-home'>
      <BackgroundImage fluid={backgroundFluidImageStack} style={{ backgroundPosition: '0% 50%' }}>
        <div className='history-content'>
          <h2 className='ics__h1'>About us</h2>
          <p className='ics__p-pullout'>
            ICS is an Australian software development company, specialising in enterprise software and advanced solutions for large organisations – particularly Government – involved in the management, measurement, planning and protection of natural values.
        </p>
          <div className="history__clients">
            <h2 className="ics__p">Who we’ve worked with –</h2>
            <ul>
              {clients.map(client => (
                <li key={client.id}>
                  <img src={client.icon.publicURL} alt={client.name} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </BackgroundImage>
    </section>
  )
}
