import React from 'react'

import Item from './TrackerSliderNavItem'

export default function TrackerSliderNav({ selected, setSelected, trackers }) {
  return (
    <nav className='drop-shadow'>
      <ul className='slider-nav'>
        {trackers.map((tracker) => (
          <Item
            key={tracker.id}
            name={tracker.name}
            icon={tracker.icon.publicURL}
            selected={selected}
            setSelected={setSelected}
            title={tracker.title}
          />
        ))}
      </ul>
    </nav>
  )
}
