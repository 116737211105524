import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

export default function TrackerSlide({ hero: { backgroundImage, description, image }, title, slug }) {
  const backgroundFluidImageStack = [
    backgroundImage.childImageSharp.fluid,
    `linear-gradient(155.16deg, rgba(242,247,255,1) 2.93%, rgba(242,247,255,0) 78.65%)`,
    `linear-gradient(0deg, var(--brand-secondary-050) 0%, var(--brand-secondary-050) 100%)`
  ].reverse()

  return (
    <BackgroundImage fluid={backgroundFluidImageStack} className='slider'>
      <div className="slider__overlay">
        <h2 className="ics__h1">{title}</h2>
        <p className="ics__p-pullout">{description}</p>
        {image && <Img className="slider__image" alt='' fluid={image.childImageSharp.fluid} style={{ objectFite: 'contain', overflow: 'visible' }} />}
        <Link to={slug} className='button-learn slider__button'>Learn More</Link>
      </div>
    </BackgroundImage>
  )
}
