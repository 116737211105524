import React from 'react'

import Layout from '../components/Layout'
import Hero from '../components/home/Hero'
import TrackerSlider from '../components/home/TrackerSlider'
import Values from '../components/home/Values'
import History from '../components/home/History'

export default function Home() {
  return (
    <Layout title='Home' url='/' page='home'>
      <Hero />
      <TrackerSlider />
      <History />
      <Values />
    </Layout>
  )
}
