import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import arrowDown from '../../assets/images/icons/chevron_down_white.svg'

export default function Hero() {
  const data = useStaticQuery(graphql`
    query Hero {
      file(relativePath: { eq: "index/720p-50-10fps.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 800, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const backgroundFluidImageStack = [
    data.file.childImageSharp.fluid,
    `linear-gradient(155.16deg, rgba(242,247,255,1) 2.93%, rgba(242,247,255,0) 78.65%)`
  ].reverse()

  return (
    <>
      <BackgroundImage
        loading='eager'
        fluid={backgroundFluidImageStack}
        style={{ backgroundColor: '#dbdbdb' }}
        className='bg-hero'>
        <h1 className='ics__h0'>
          Manage your organisation with Geospatial Artificial Intelligence
        </h1>
        <img src={arrowDown} alt="Arrow pointing downwards" className="index__arrow" />
      </BackgroundImage>
    </>
  )
}
