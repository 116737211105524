import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

export default function Values() {
  const data = useStaticQuery(graphql`
    query Values {
      file(relativePath: { eq: "index/values_001.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 800, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const values = [
    'Sustainability',
    'Commitment',
    'Evolution',
    'Integrity',
    'Longevity',
    'A Holistic Approach',
  ]

  const backgroundFluidImageStack = [
    data.file.childImageSharp.fluid,
    `linear-gradient(100.44deg, #2D73E3 20.8%, rgba(242, 247, 255, 0) 89.99%)`
  ].reverse()

  return (
    <section className='values-component'>
      <BackgroundImage fluid={backgroundFluidImageStack} className='values-content'>
        <h2 className='ics__h1'>Our values</h2>
        <ul className='values-links'>
          {values.map((value, index) => (
            <li key={index + value} className='ics__p-pullout'>
              {value}
            </li>
          ))}
        </ul>
      </BackgroundImage>
    </section>
  )
}
