import React from 'react'

export default function TrackerSliderNavItem({
  name,
  icon,
  selected,
  setSelected,
  title,
}) {
  const selectHandler = () => {
    setSelected(name)
  }

  const style1 = {
    borderBottom: 'solid 3px #2d73e3',
  }

  return (
    <li
      className='slider-nav-item'
      style={selected === name ? style1 : null}
      onClick={selectHandler}>
      <img src={icon} alt={`Icon symbol for ${name} tracker.`} />
      <h3 className='slider-nav-item__title ics__h3'>{title}</h3>
    </li>
  )
}
