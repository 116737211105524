import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import TrackerSliderNav from './TrackerSliderNav'
import TrackerSlide from './TrackerSlide'

export default function TrackerSlider() {
  const [selected, setSelected] = useState('lands')

  const {
    dataJson: { trackers: { lands, flame, biodiversity, grants, visit } },
  } = useStaticQuery(graphql`
    query TrackerSlider {
      dataJson {
        trackers {
          lands {
            id
            name
            title
            slug
            icon {
              publicURL
            }
            hero {
              description
              image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
              }
            }
              backgroundImage {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          flame {
            id
            name
            title
            slug
            icon {
              publicURL
            }
            hero {
              description
              image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
              }
            }
              backgroundImage {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          biodiversity {
            id
            name
            title
            slug
            icon {
              publicURL
            }
            hero {
              description
              image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
              }
            }
              backgroundImage {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          grants {
            id
            name
            title
            slug
            icon {
              publicURL
            }
            hero {
              description
              backgroundImage {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          visit {
            id
            name
            title
            slug
            icon {
              publicURL
            }
            hero {
              description
              backgroundImage {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 50) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const trackersArr = [lands, flame, biodiversity, grants, visit]

  return (
    <section className='tracker-slider'>
      <TrackerSliderNav
        selected={selected}
        setSelected={setSelected}
        trackers={trackersArr}
      />
      {trackersArr.map(tracker => {
        return tracker.name === selected && <TrackerSlide key={tracker.id} {...tracker} />
      })}
    </section>
  )
}
